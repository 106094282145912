import { Component, Vue, Prop } from 'vue-property-decorator';
import { CONST } from '@/shared/utils/Constants';

@Component({ name: 'document-upload' })
export default class DocumentUpload extends Vue {
  @Prop({ default: false })
  private isLoading!: boolean;
  @Prop({ default: false })
  private isUploaded!: boolean;
  @Prop({ default: false })
  private disabled!: boolean;

  @Prop({ default: true })
  private isRequired!: boolean;

  @Prop({ default: () => 'pdf' })
  private allowedExtensions!: string;

  // private allowedExtensions = 'pdf,jpg'; // wanted to do PROP with dynamic Extensions, but `Ext` messages get extensions in i18n message as {0},{1},{2},{3} and {4} for current VALUE (ie dynamic number of variables), so should write custom validation to have good and correct messages

  get accept() {
    return this.allowedExtensions.split(',').map(x=> '.' + x).join(',');
  }

  private document: any = null;
  get isDocumentSelected() {
    return this.document !== null;
  }
  private onChange(fileInfo: any) {
    const isInputHasFile = fileInfo && fileInfo.name ? true : false;
    this.$emit('checkInputFile', isInputHasFile, fileInfo);
  }

  upload() {
    console.log('this.document :>> ', this.document);
    // console.log('+this.document.size <= CONST.maxFileSize5MB :>> ', +this.document.size <= CONST.maxFileSize5MB);
    let arrExt = this.allowedExtensions.split(',');
    if (this.document && +this.document.size <= CONST.maxFileSize5MB && (this.document.type?.includes('pdf') || arrExt.some(x => this.document.name.toLowerCase().endsWith(x.toLowerCase()) ))) {
      this.$emit('upload', {
        document: this.document,
        id: '',
      });
    }
  }
}
